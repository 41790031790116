.container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  gap: 10px;
}

.input {
  input {
    text-align: right !important;
  }

  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}
