@use "src/styles/bootstrap";
@use "sass:map";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.jobInputContainer {
  width: 75%;

  & > div:first-child {
    width: 100%;
  }
}

.productName {
  height: auto;
  white-space: normal;
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "laptop")) {
  .jobInputContainer {
    width: 65%;
  }
}
