.container {
  height: 100%;
}

.products {
  display: flex;
  flex-direction: column;

  .product {
    text-align: left;
    width: 300px;
    height: auto;
    white-space: normal;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.colorContainer {
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
