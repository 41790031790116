.transparentLabel {
  label {
    opacity: 0;
  }
}

.deleteButton {
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.addButton {
  label {
    opacity: 0;
  }
}
