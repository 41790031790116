.formItem {
  width: calc(100% - 35px);
}

:global {
  .add-item-button {
    align-self: end;
  }

  .ant-form-item-has-error + .add-item-button {
    margin-bottom: 22px;
  }
}
