.container {
  font-size: 16px;

  :global {
    .ant-tree-switcher {
      display: none !important;
    }

    .ant-tree-title {
      font-size: 16px !important;
    }
  }
}

@media print {
  .container {
    :global {
      .ant-input-number {
        border: none;
        background: none;
      }
    }
  }
}