.pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 4px;
}

.paginationPage:has(.paginationActive) {
  border: 1px solid #1677ff;
  border-radius: 6px;
  color: #1677ff;
}

.paginationButton {
  width: 35px;
  padding: 0 !important;
}
