.container {
  display: flex;
  gap: 8px;
}

.widgets {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
}

.calendarContainer {
  position: relative;
  padding: 10px 0;
}

.rangePicker {
  display: flex;
  justify-content: end;
  margin: 0 10px;
}

.calendar {
  box-shadow: none;
}

.calendar, .chartContainer {
  width: 400px;
}

.chartContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
}

.collapseButton {
  position: absolute;
  left: 10px;
  top: 10px;
}

.calendarContainer {
  .collapseButton {
    top: 8px;
  }
}

.expandWidgets {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: self-start;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
