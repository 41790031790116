.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 5px;
  min-width: 500px;
}

.colorContainer {
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
