.container {
  display: flex;
  gap: 5px;
  width: 100%;

  .formItem {
    width: calc(100% - 35px);

    :global {
      .ant-form-item-label {
        text-align: left !important;
      }
    }
  }

  :global {
    .add-item-button {
      align-self: end;
    }

    .ant-form-item-has-error + .add-item-button {
      margin-bottom: 22px;
    }
  }
}
