.item {
  margin-bottom: 0;
  padding-bottom: 5px;
  :global {
    .ant-form-item-label {
      text-align: left !important;
    }
  }
}

.jobSettings {
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.input {
  width: 100%;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: start;
}
