@media print {
  .container {
    display: none;
  }
}

.activeRow {
  background: #E6F4FF;

  :global {
    .ant-table-column-sort {
      background: #E6F4FF !important;
    }
  }

  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #E6F4FF !important;
      }
    }
  }
}
