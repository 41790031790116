.container {
  height: calc(100vh - 90px);
  overflow: auto;
  margin: -10px -10px 0;
  padding: 10px 10px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}
