.form {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  position: sticky;
  align-self: end;
  top: 10px;
  right: 20px;
  height: 40px;
  padding-bottom: 10px;
  z-index: 100;

  button {
    width: 100%;
  }
}

.container {
  display: flex;
  gap: 20px;

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.teeth {
  position: relative;
}
