.container {
  position: relative;
}

.collapse {
  position: absolute;
  z-index: 10;
  bottom: -13px;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
