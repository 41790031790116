.uploadFiles {
  margin-top: 10px;

  :global {
    .ant-upload-list-item-actions {
      a {
        margin-right: 15px;
      }
    }

     .ant-upload-list-item-container {
      width: 88px !important;
      height: 88px !important;
    }

    .ant-upload-select {
      width: 88px !important;
      height: 25px !important;
    }
  }
}
