.tooth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.88);
  background-color: rgba(0, 0, 0, 0.02);
  text-align: center;
  cursor: pointer;
}

.selected {
  background: darken(#E6F4FF, 1);
  box-shadow: 0 0 2px darken(#E6F4FF, 20);
}

.error {
  border: 2px solid #FF0000FF;
  box-shadow: 0 0 2px #FF0000FF;
}