body {
  margin: 0;
  background: #f5f5f5;
}

.ant-picker-calendar {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.ant-tabs-nav-list {
  gap: 10px;
}

.table-actions {
  opacity: 0;
}

.ant-tabs-content {
  position: static !important;
}

.ant-table-cell-row-hover {
  .table-actions {
    opacity: 1;
    display: flex;
  }
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-select-selector {
  font-size: 16px !important;
}

.ant-dropdown-menu-item-active:has(.choose-product-popup--no-background) {
  background: none !important;
}
