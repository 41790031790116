.jobs {
  width: 100%;
  margin-top: 10px;

  :global {
    .ant-tree-switcher {
      display: none;
    }

    .ant-tree-treenode, .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }
}

.job {
  display: flex;
  align-items: start;
  gap: 10px;
  width: 100%;
}

.products {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 8px;
}

.productDropdown {
  :global {
    .ant-tree-switcher, .ant-tree-switcher-noop {
      display: none !important;
    }

    .ant-tree-treenode, .ant-tree-node-content-wrapper {
      width: 100% !important;
    }

  }
}

.error {
  margin-top: 8px;
  width: 100%;
}
