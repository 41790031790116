.container {
  margin-bottom: 20px;
  font-size: 12px;
}

.details {
  display: flex;
  align-items: center;
  gap: 10px;

  & > div:first-child {
    flex-grow: 1;
  }

  .row:last-child {
    border-bottom: none;
  }
}

.title {
  font-weight: 700;
  text-align: center;
}

.row {
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .8);
}

.row + .row {
  border-top: none;
}

.row:not(:has(.column)) {
  padding: 10px;
}

.column {
  width: 50%;
  padding: 10px;
}

.withRows {
  padding: 0;

  .row {
    border: none;
  }

  .row + .row {
    border-top: 1px solid rgba(0, 0, 0, .8);
  }
}

.column + .column {
  border-left: 1px solid rgba(0, 0, 0, .8);
}
