.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.block {
  height: calc(100vh - 100px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.job {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.row {
  margin-top: 20px;
  border-top: 1px solid #d9d9d9;
}
