.jawButtons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;

  .tooth {
    padding: 0 5px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    color:rgba(0, 0, 0, 0.88);
    background-color: rgba(0, 0, 0, 0.02);
    text-align: center;
    cursor: pointer;
  }

  .selected {
    background: darken(#E6F4FF, 1);
    box-shadow: 0 0 2px darken(#E6F4FF, 20);
  }
  
  .disabled {
    color:rgba(0, 0, 0, 0.58);
    cursor: default;
  }
}
