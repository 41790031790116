.tree {
  :global {
    .ant-tree-switcher {
      line-height: 34px !important;
    }

    .ant-tree-list-holder-inner {
      padding: 5px !important;
    }

    .drop-target {
      border: 2px solid #6bcc6b !important;
      border-radius: 3px;
    }

    .ant-tree-drop-indicator {
      display: none;
    }

    .tree-actions {
      display: none;
    }

    .ant-tree-treenode:hover {
      .tree-actions {
        display: flex;
      }
    }
  }
}
