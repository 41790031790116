.container {

  & > div {
    width: 100%;
  }

  :global {
    .ant-form-item {
      margin-bottom: 10px !important;
    }

    .ant-tree-switcher {
      display: none;
    }

    .ant-tree-treenode, .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }
}

.description {
  :global {
    .ant-form-item-label {
      text-align: center !important;
      font-weight: 700;
    }
  }
}

.title {
  text-align: center;
  font-weight: 700;
  padding-bottom: 10px;
}

.jobItem {
  display: flex;
  align-items: center;
  gap: 5px;

  .jobName {
    display: inline-block;
    width: 33%
  }

  .productName {
    display: inline-block;
    width: 65%
  }
}
