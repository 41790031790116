.panelLeft {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 35%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.teeth {
  position: relative;
}
