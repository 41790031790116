.actionButtons {
  display: flex;
  justify-content: end;
  position: sticky;
  top: 10px;
  right: 20px;
  height: 40px;
  padding-bottom: 10px;
  z-index: 100;

  button {
    width: 100%;
  }
}

.addCellItem {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px;

  .placeholder {
    position: absolute;
    top: 50%;
    inset-inline-start: 11px;
    inset-inline-end: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
}

.extrasSelect {
  :global {
    .ant-select-tree-treenode {
      width: 100% !important;
    }
  }
}

.sum {
  margin-top: 10px;
  float: right;
}

.cellBaseline {
  vertical-align: baseline;
}

.extraItem, .partItem {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
