.details {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.total {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.teethFormula {
  & > div {
    width: auto;
  }
}
