.content {
  display: flex;
  gap: 8px;
  position: relative;

  & > div {
    height: calc(100vh - 20px);
    overflow: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.fullCase {
  position: absolute;
  z-index: 1;
  left: 60%;
  top: 0;
  width: 40%;
  backdrop-filter: blur(10px);
}

.activeRow {
  background: #E6F4FF;

  :global {
    .ant-table-column-sort {
      background: #E6F4FF !important;
    }
  }

  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #E6F4FF !important;
      }
    }
  }
}
