@media print {
  body {
    visibility: hidden;
    margin:0; padding:0;
  }

  .container {
    display: block !important;
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: #ffffff;
  }
}
