.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.collapse {
  border-radius: 0;

  .panelGreen {
    :global {
      .ant-collapse-header {
        background: green;
        color: rgba(255, 255, 255, .9);
      }
    }
  }

  .panelOrange {
    :global {
      .ant-collapse-header {
        background: orange;
        color: rgba(255, 255, 255, .9);
      }
    }
  }

  .panelRed {
    :global {
      .ant-collapse-header {
        background: red;
        color: rgba(255, 255, 255, .9);
      }
    }
  }

  .panelBlue {
    :global {
      .ant-collapse-header {
        background: blue;
        color: rgba(255, 255, 255, .9);
      }
    }
  }

  :global {
    .ant-collapse-content-box {
      padding: 0 !important;
      border-radius: 0;
    }

    .ant-descriptions-view {
      border-radius: 0 !important;
    }
  }
}
